import { FunctionComponent } from 'react'
import { GetStaticPropsContext, InferGetStaticPropsType } from 'next'
import { NextSeo } from 'next-seo'

import { SupportedLocale } from 'data-access/domain/constants'

import { getNotFoundPageProps } from 'src/domain/notFoundPageProps.domain'
import { seoData } from 'src/utils/seo.util'
import MisterLayout from '../components/layout/MisterLayout'
import MisterSectionLoop from '../components/shared/sections/MisterSectionLoop'
import { defaultGlobals, GlobalsContext } from '../contexts/Globals.context'

const NotFound: FunctionComponent<InferGetStaticPropsType<typeof getStaticProps>> = ({ globals, layoutProps, sections, seo }) => {
  return (
    <GlobalsContext.Provider value={globals || defaultGlobals}>
      <NextSeo {...seoData(seo)} />

      <MisterLayout {...layoutProps}>
        <MisterSectionLoop sections={sections} as='article' />
      </MisterLayout>
    </GlobalsContext.Provider>
  )
}

export const getStaticProps = async ({ locale, draftMode = false }: GetStaticPropsContext) => ({
  props: await getNotFoundPageProps({ locale: locale as SupportedLocale, draftMode }),
})

export default NotFound
